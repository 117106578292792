body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Lato";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 62.5%;
}

[class^="Mui"] {
  font-size: 1.6rem !important;
}

/* Scrollbar */
::-webkit-scrollbar {
  border: 0;
  height: 1rem;
}

/* for scroll bar */
.ScrollBar {
  padding-right: 2rem;
  height: 30rem;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.8s ease-in-out;
}

::-webkit-scrollbar {
  width: 0.6rem !important;
  height: 0.6rem !important;
}
::-webkit-scrollbar:hover {
  width: 1rem !important;
  height: 1rem !important;
}

/* Track */

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 1rem;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #1470a4 !important;
  border-radius: 1rem;
}

.Image-Css {
  width: calc(100vh - 100px) !important;
  height: fit-content !important;
}

.img-div {
  width: 800px !important;
  height: 1200px !important;
  /* position: relative; */
  /* overflow: scroll; */
  border-radius: 1rem;
  transition: all 0.6s ease-in-out;
}

.ONHOVER-IMAGE {
  width: 150px !important;
  height: 125px !important;
  position: relative;
  overflow: contain;
}

.ONHOVER-IMAGE:hover .onHover-overlay {
  opacity: 1;
  font-weight: 400;
  font-size: 12px !important;
}

.ONHOVER-IMAGE:hover .onHover-overlay img {
  opacity: 5;
}

.onHover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(212, 224, 235, 0.5);
  opacity: 0;
  transition: 0.5s;
  justify-items: center;
  padding-top: 35px;
  text-shadow: rgba(190, 192, 228, 0.527) 0px 0px 10px;
  color: #000078;
  font-size: 1rem;
  font-weight: 400;
}

.image-container {
  width: 100% !important;
  height: 200px !important;
  position: relative;
  overflow: scroll;
  border-radius: 1rem;
}

.img-fill {
  width: 50.8rem;
}

@media (max-width: 1250px) {
  html {
    font-size: 50%;
  }
  .img-fill {
    width: 45rem;
  }
  .eye-div {
    position: absolute;
    width: 35px !important;
    height: 36px !important;
    right: 0px !important;
    top: 18px !important;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 45%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  max-width: 144rem !important;
}

.containerr {
  max-width: 144rem !important;
  min-height: 100vh !important;
}

@font-face {
  font-family: "poppins-Med";
  src: url("./Components/Assets/Fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "poppins-B";
  src: url("./Components/Assets/Fonts/Poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: "poppins-semi-B";
  src: url("./Components/Assets/Fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "poppins-reg";
  src: url("./Components/Assets/Fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Lato";
  src: url("./Components/Assets/Fonts/Lato2OFL/Lato2OFL/Lato-Regular.ttf");
}

.poppins-reg {
  font-family: "poppins-reg";
}

.poppins-semi-B {
  font-family: "poppins-semi-B";
}

.poppins-B {
  font-family: "poppins-B";
}

.poppins-Med {
  font-family: "poppins-Med";
}

.secondary-bg {
  background: #f5f5f5 !important;
}

.graph-cards {
  background: #ffffff;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
  padding: 16px 27px;
  height: 100%;
  width: 100%;
}

.ticket-cards {
  background: #ffffff;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06) !important;
  border-radius: 3.36424px !important;
  width: 100% !important;
  height: 112px !important;
  transition: ease-in-out 1s;
}

.ticket-cards-2 {
  padding: 16px;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  background: white;
  box-shadow: 0px 4px 80px 0px #10182814;
}

.ticket-cards:hover {
  transform: scale(1.05);
}

.blu-card {
  border: 0.284615px solid #daeeff;
  box-shadow: 0px 11.3846px 24.6667px rgba(16, 30, 115, 0.06);
  border-radius: 7.58974px;
}

.min-100-vh {
  min-height: 100vh !important;
}

.ticket-icon {
  width: 30px;
  height: 31px;
  margin-right: 9px;
}

.h-53 {
  height: 53px !important;
}

.h-900 {
  min-height: calc(100vh - 320px) !important;
  max-height: fit-content !important;
  overflow: auto !important;
}

.position-inherit {
  position: inherit !important;
}

.tab-buttons {
  width: 139px !important;
  height: 37px !important;
  background: #f7f7f7 !important;
  border: 1px solid #e8eef0 !important;
  border-radius: 6px 6px 0px 0px !important;
  margin-right: 15px !important;
  margin-top: 10px !important;
  /* text */
  color: #63696b !important;
  font-family: "poppins-Med" !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.fab-button {
  position: absolute !important;
  top: 0px !important;
  z-index: 999 !important;
  right: 0% !important;
  width: 70px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: #1470a4 !important;
  color: #ffffff !important;
  font-size: 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* grid-5 */
.grid-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
}

@media (max-width: 1440px) {
  .grid-5 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1080px) {
  .grid-5 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 720px) {
  .grid-5 {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* grid-2 */
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

@media (max-width: 1440px) {
  .grid-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1080px) {
  .grid-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 720px) {
  .grid-2 {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* grid-3 */
.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

@media (max-width: 1440px) {
  .grid-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1080px) {
  .grid-3 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 720px) {
  .grid-3 {
    grid-template-columns: repeat(1, 1fr);
  }
}

/* grid-4 */
.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

@media (max-width: 1440px) {
  .grid-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1080px) {
  .grid-4 {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 720px) {
  .grid-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.Lato {
  font-family: "Lato";
  font-style: normal;
  line-height: 4.3rem;
}

.dash-cards {
  background: #ffffff;
  box-shadow: 0rem 1.2rem 2.6rem rgba(16, 30, 115, 0.04);
  border-radius: 0.8rem;
}

.info-card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding: 10px;
  transition: all 0.6s;
  font-weight: bolder !important;
}

.info-card:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  padding: 10px;
  transform: scale(1.02);
  font-weight: bolder !important;
}

.brdr-1 {
  border: 1px dotted #1470a4 !important;
}

.h-100px {
  height: 100px !important;
}

.w-120 {
  width: 130px !important;
}

.h-200 {
  height: 130px !important;
  overflow-x: auto !important;
}

.h-250 {
  max-height: 250px !important;
}

.h-300 {
  max-height: 300px !important;
}

.h-40 {
  height: 40px !important;
}

.h-445 {
  height: 445px !important;
}

.h-500 {
  height: 500px !important;
  overflow-y: auto !important;
  overflow-x: hidden;
}

.h-600 {
  height: 600px !important;
  overflow-y: auto !important;
  overflow-x: hidden;
}

.h-10 {
  height: 100px !important;
}

.text-blue {
  color: #1470a4 !important;
}

.color-info {
  color: #59b0e3 !important;
}

.txt-black {
  color: #25282b !important;
}

.txt-grey {
  color: #989c9f !important;
}

.bg-highlighted {
  background-color: rgb(214, 220, 233) !important;
  color: #304fa3 !important;
}

.bg-aliceblue {
  background-color: aliceblue !important;
}

.bg-aliceblue-light {
  background-color: #addaff85 !important;
}

.bg-primar {
  background-color: #59b0e3 !important;
}

.bg-primar-light {
  background-color: #daeeff !important;
}

.bg-scndry {
  background-color: #bcdaee !important;
}

.btn-dark {
  background-color: #1a6da5 !important;
  color: white;
  font-weight: 400;
  font-size: 14px;
  border: 0px !important;
  height: 45px;
  width: 100%;
}

.reset-btn {
  position: absolute !important;
  right: 0% !important;
  top: 0% !important;
  z-index: 1 !important;
}

.absolute-input {
  position: absolute !important;
  width: 100% !important;
  right: 0% !important;
  top: 0% !important;
  z-index: 1 !important;
}

.text-grey-black {
  color: #525252 !important;
}

.fw-800 {
  font-weight: 800;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600 !important;
}

.fs-10 {
  font-size: 1rem !important;
}

.fs-12 {
  font-size: 1.2rem !important;
}

.fs-14 {
  font-size: 1.4rem !important;
}

.fs-16 {
  font-size: 1.6rem !important;
}

.fs-18 {
  font-size: 1.8rem !important;
}

.fs-20 {
  font-size: 2rem !important;
}

.fs-24 {
  font-size: 2.4rem !important;
}

.fs-36 {
  font-size: 3.6rem !important;
}

.img-fill {
  width: 50.8;
}

/* Input */
select,
textarea,
input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.3rem 1rem;
  gap: 1.3rem;
  width: 100%;
  height: 4.5rem;
  border: 0.1rem solid #ded2d9;
  border-radius: 0.5rem;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-family: "Nunito Sans";
  font-style: normal;
  margin-bottom: 1.8rem !important;
  font-size: medium !important;
}

.form-check-input:checked {
  background-color: #1470a4 !important;
}

textarea {
  min-height: 70px;
}

label {
  width: 6rem;
  height: 1.7rem;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: #828282;
  margin-bottom: 0.5rem;
}

.form-check-input {
  box-sizing: border-box;
  background: #1470a4;
  border: 0.2rem solid #1470a4;
  border-radius: 0.2rem;
}

.form-control,
.date-styles input {
  height: 30px !important;
  margin-bottom: 0px !important;
}

.text-primry {
  color: #1470a4 !important;
}

.text-primary {
  color: #1470a4 !important;
}

.text-secondary {
  color: #a1a1a1 !important;
}

.bg-secndry {
  background: #f5f4f7 !important;
}

.btn-primary {
  background: #1470a4 !important;
  gap: 1.3rem;
  padding: 1.3rem 1rem 1.2rem;
  border-radius: 0.6rem;
}

@media (max-width: 1250px) {
  html {
    font-size: 50%;
  }
  .img-fill {
    width: 45rem;
  }
}

.borderBottom {
  border: 0.2rem solid rgb(50, 51, 69) !important;
}

.bg-lightBlue {
  background: aliceblue !important;
}

.navLinks {
  color: #1470a4 !important;
  font-weight: 700 !important;
  text-decoration: none !important;
  font-size: 2.2rem !important;
}

.img-placement {
  position: absolute !important;
  right: 2.7rem;
  top: 1rem;
}

.img-placement2 {
  position: absolute !important;
  right: -1rem;
  top: 1rem;
}

.img-placement3 {
  position: absolute !important;
  right: 7rem;
  top: 1rem;
}

.searchCard {
  background: #fbfbfb;
  border-radius: 1rem;
  border: 0.1rem solid #000078;
  width: 100%;
}

li {
  font-size: 1.6rem;
}

.text-style {
  font-size: 1.6rem;
}

/* Loader */

.loader {
  /* margin-top: .8rem; */
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: inline-block;
  border-top: 0.4rem solid #c2c5c8;
  border-right: 0.4rem solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border-bottom: 0.4rem solid #000078;
  border-left: 0.4rem solid transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.selected {
  background: #97ceff;
}

.selected-recording {
  border-right: 4px solid #1470a4 !important;
  box-shadow: rgba(192, 192, 212, 0.25) 0px 30px 60px -12px inset,
    rgba(201, 199, 199, 0.3) 0px 18px 36px -18px inset !important;
  padding: 10px;
  transition: all 0.6s;
  transform: scale(1.05);
  font-weight: bolder !important;
}

.selected-medicalStatus {
  border: 2px solid #279affa4 !important;
  box-shadow: 0 3px 10px 2px #444 !important;
  transform: scale(1.05);
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 16px !important;
}

.rounded_Button .MuiButtonBase-root {
  border-radius: 24px !important;
}

.fabCustom {
  min-width: 35px !important;
  padding: 0 8px !important;
  height: 35px !important;
}

.border_bottom {
  border-bottom: 2px solid black;
  padding: 7px;
  margin: 7px;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: grey !important;
  overflow: visible !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

/* .pans-output { */
/* height: 510px !important; */
/* width: 450px !important; */
/* } */

.eye-div {
  position: absolute;
  width: 50px;
  height: 43px;
  right: 1px;
  top: 23px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.audio-player-container {
  font-size: 16px !important;
}

.audio-player-container audio {
  width: 98% !important;
}

.absolute-center-loader {
  position: absolute !important;
  z-index: 1;
  left: 40% !important;
  top: 40%;
  background: #ffffff !important;
  padding: 30px !important;
  max-height: 100px !important;
  min-width: 100px !important;
}

.transition-loading {
  transition: all 0.6s ease-in-out;
}

.user-profile-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: #1470a4;
  color: #ffffff;
  font-size: 16px;
  border-radius: 50%;
  font-weight: 500;
}

.switch-box {
  border-radius: 20px;
  position: relative;
  background: #E8F7FF;
  box-shadow: 1px 1px 2px rgb(165, 165, 165) inset;
}
.switch-box .switch {
  width: 130px;
  height: 40px;
  border-radius: 20px;
  background: #279affa4;
  position: absolute;
  box-shadow: 1px 0 2px #4f87b8;
  transition: all 0.5s ease;
}
.switch-box .MuiButton-root {
  border-radius: 20px;
  width: 130px;
  height: 40px;
  font-weight: bold;
  font-size: 14px !important;
  transition: all 0.2s 0.1s ease;
}
.switch-box .MuiButton-root:hover {
  opacity: 0.8;
}

/* Add these styles to handle ResizeObserver */
.ag-center-cols-container {
  width: 100% !important;
  min-width: 100%;
}

.ag-center-cols-viewport {
  overflow-x: hidden !important;
}

.ag-body-viewport {
  overflow-x: hidden !important;
}

.ag-theme-alpine {
  --ag-grid-size: 5px;
  --ag-list-item-height: 80px;
}

/* Prevent audio player from causing layout shifts */
.ag-cell-value {
  height: 100%;
  display: flex;
  align-items: center;
}
