/* datePickerStyles.css */

/* Customize the calendar container */
.react-datepicker-popper {
  width: 320px; /* Adjust the width as needed */
  
}

/* Customize the calendar header */
.react-datepicker__header {
  padding: 10px;
}

/* Customize the calendar month and year */
.react-datepicker__current-month,
.react-datepicker__current-year {
  font-size: 16px;
}

/* Customize the calendar weekdays */
.react-datepicker__day-name {
  padding: 8px;
}

/* Customize the calendar days */
.react-datepicker__day {
  padding: 8px;
  font-size: 14px;
  height: 30px; /* Adjust the height as needed */
}

/* Customize the calendar navigation arrows */
.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  padding: 8px;
  font-size: 16px;
}

/* Customize the selected range */
.react-datepicker__day--in-range {
  background-color: #e0e0e0;
  color: #333;
}

/* Customize the selected start date */
.react-datepicker__day--start {
  background-color: #2196f3;
  color: #fff;
}

/* Customize the selected end date */
.react-datepicker__day--end {
  background-color: #2196f3;
  color: #fff;
}

/* Customize the selected day */
.react-datepicker__day--selected {
  background-color: #1976d28b;
  color: #fff;
}

/* Customize the calendar today's date */
.react-datepicker__day--today {
  border: 1px solid #1976d28b;
  border-radius: 50%;
  color: #1976d2;
  font-weight: bold;
}

.react-datepicker__close-icon {
  padding: 0 18px 15px 0;
}

.date-picker-field {
  display: flex;
  max-width: 164px;
}

.date-input {
  width: 100%;
  max-height: 46px;
  border: 1px solid #000000;
}

.calenderIcon-left {
  position: absolute;
  top: 9px;
  right: 22%;
}

.calenderIcon-right {
  position: absolute;
  top: 9px;
  right: 22%;
}

.week-btn {
  /* text-nowrap fs-5 mb-3 bg-primar h-100 mt-1 py-3 me-2 */
  font-size: 12px !important;
  height: 44px !important;
  margin-right: 5px !important;
  
}
