#select-patient {
  /* height: 45px !important; */
  height: 13px !important;
  margin-bottom: 0px !important;
  padding: 7px !important;
  font-size: 16px !important;
}

#select-facility {
  padding: 15.32px 12px;
  min-height: 11px !important;
  font-size: 16px !important;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  font-size: 16px;
}
